var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('validation-provider',{attrs:{"vid":"startDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-date',{attrs:{"label":"Data inicial","error-messages":errors,"solo":"","dense":"","flat":"","clearable":""},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}})]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"vid":"endDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-date',{attrs:{"label":"Data final","error-messages":errors,"solo":"","dense":"","flat":"","clearable":""},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"success","small":"","elevation":"0","disabled":_vm.selected.length <= 0,"loading":_vm.loadingDownloadSelected},on:{"click":_vm.downloadSelected}},[_vm._v(" Baixar selecionados "),_c('v-icon',{attrs:{"right":""},domProps:{"textContent":_vm._s('mdi-download')}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"disable-filtering":"","disable-sort":"","server-items-length":_vm.countReports,"loading":_vm.loadingReports,"headers":_vm.headersReports,"items":_vm.reports,"options":_vm.paginationReports,"footer-props":{
          'items-per-page-options': [10, 20, 50, 100],
        },"show-select":""},on:{"update:options":[function($event){_vm.paginationReports=$event},_vm.loadReports]},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.totalHoursWorked",fn:function(ref){
        var item = ref.item;
return [(item.totalHoursWorked)?[_vm._v(" "+_vm._s(((item.totalHoursWorked.hours) + ":" + (item.totalHoursWorked.minutes)))+" ")]:_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"success","small":"","icon":"","loading":item.id === _vm.loadingReportPdfId},on:{"click":function($event){return _vm.downloadReportPdf(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-open-in-new')}})],1)]}}],null,true)},[_c('small',[_vm._v("Abrir")])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from '@/plugins/axios'
import axiosCustomer from '@/plugins/axios-customer'

export default {

  async list (data) {
    const parametros = new URLSearchParams(data).toString()
    return await axios.get(`reports?${parametros}`)
  },

  async get (id) {
    return await axios.get(`reports/${id}`)
  },

  async insert (data) {
    return await axios.post('reports', data)
  },

  async update (id, data) {
    return await axios.put(`reports/${id}`, data)
  },

  async setPrinted (id, data) {
    return await axios.patch(`reports/${id}/set-printed`, data)
  },

  async delete (id) {
    return await axios.delete(`reports/${id}`)
  },

  async pdf (id, query, isCustomer) {
    const queryString = new URLSearchParams(query).toString()
    return await (isCustomer ? axiosCustomer : axios).get(`reports/${id}/pdf?${queryString}`)
  },

}

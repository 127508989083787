<template>
  <div>
    <v-row class="mt-2">
      <v-col>
        <validation-provider v-slot="{ errors }" vid="startDate">
          <input-date
            v-model="filters.startDate"
            label="Data inicial"
            :error-messages="errors"
            solo
            dense
            flat
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col>
        <validation-provider v-slot="{ errors }" vid="endDate">
          <input-date
            v-model="filters.endDate"
            label="Data final"
            :error-messages="errors"
            solo
            dense
            flat
            clearable
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          color="success"
          small
          elevation="0"
          :disabled="selected.length <= 0"
          @click="downloadSelected"
          :loading="loadingDownloadSelected"
        >
          Baixar selecionados
          <v-icon right v-text="'mdi-download'" />
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          v-model="selected"
          disable-filtering
          disable-sort
          :server-items-length="countReports"
          :loading="loadingReports"
          :headers="headersReports"
          :items="reports"
          :options.sync="paginationReports"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100],
          }"
          @update:options="loadReports"
          show-select
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | date }}
          </template>

          <template v-slot:[`item.totalHoursWorked`]="{ item }">
            <template v-if="item.totalHoursWorked">
              {{
                `${item.totalHoursWorked.hours}:${item.totalHoursWorked.minutes}`
              }}
            </template>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  class="ml-2"
                  small
                  icon
                  @click="downloadReportPdf(item)"
                  :loading="item.id === loadingReportPdfId"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-text="'mdi-open-in-new'" small />
                </v-btn>
              </template>
              <small>Abrir</small>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import customersDashboardApi from '@/api/customers-dashboard'
import reportsApi from '@/api/reports'
import downloadBase64File from '@/utils/download-base64-file'

export default {
  components: {
    InputDate: () => import('@/components/input/InputDate'),
  },

  data: () => ({
    // Reports
    loadingReports: false,
    loadingReportPdfId: false,
    loadingDownloadSelected: false,
    paginationReports: {
      itemsPerPage: 20,
    },
    headersReports: [
      { text: 'Número', value: 'id' },
      { text: 'Data', value: 'date' },
      {
        text: 'Modalidade de atendimento',
        value: 'serviceModality.description',
      },
      { text: 'Horas', value: 'totalHoursWorked' },
      { text: '', value: 'actions', align: 'right' },
    ],
    countReports: 0,
    reports: [],
    filters: {},
    selected: [],
  }),

  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadReports()
      },
    },
  },

  methods: {
    async loadReports() {
      try {
        this.loadingReports = true

        const response = await customersDashboardApi.reports({
          limit: this.paginationReports.itemsPerPage,
          offset:
            this.paginationReports.page * this.paginationReports.itemsPerPage -
            this.paginationReports.itemsPerPage,
          ...(this.filters.startDate
            ? { startDate: this.filters.startDate }
            : {}),
          ...(this.filters.endDate ? { endDate: this.filters.endDate } : {}),
        })

        this.reports = response.data.reports
        this.countReports = response.data.count
      } catch (e) {
        console.log(e)
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingReports = false
      }
    },

    async downloadReportPdf(report, options = {}) {
      try {
        if (!options?.downloadSelected) this.loadingReportPdfId = report.id

        const params = {}
        if (options?.returnType) params.returnType = options.returnType

        const response = await reportsApi.pdf(report.id, params, true)

        if (!options?.returnType) {
          const url =
            process.env.VUE_APP_API +
            'downloads/reports/' +
            response.data.filename
          window.open(url, '_blank')
        }

        return response
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingReportPdfId = null
      }
    },

    async downloadSelected() {
      this.loadingDownloadSelected = true

      for (const report of this.selected) {
        const res = await this.downloadReportPdf(report, {
          returnType: 'base64',
          downloadSelected: true,
        })
        downloadBase64File(res.data.pdf, `relatorio-linq-${report.id}.pdf`)
      }

      this.loadingDownloadSelected = false
    },
  },
}
</script>
